<template>
  <div ref="el" class="hero-calc calc-fluid">
    <div class="amount-loan">
      <p class="amount-title">{{ amount.title }}</p>
      <p class="amount-value">
        <span id="amount-value">{{ addSeparator(amountValue) }} {{ amount.unit }}</span>
      </p>
    </div>
    <div class="amount-slider">
      <slider
        v-model="amountValue"
        :step="Number(amount.step)"
        :range="{ min: Number(amount.minimum), max: Number(amount.maximum) }"
        @update:model-value="(value) => onChangeAmount(value)"
      ></slider>
      <div class="amount-range">
        <p>{{ addSeparator(amount.minimum) }} {{ amount.unit }}</p>
        <p>{{ addSeparator(amount.maximum) }} {{ amount.unit }}</p>
      </div>
    </div>
    <div class="duration-loan">
      <p class="duration-title">{{ duration.title }}</p>
      <p class="duration-value">
        <span id="duration-value">{{ durationValue }} {{ duration.unit }}</span>
      </p>
    </div>
    <div class="duration-slider">
      <slider
        v-model="durationValue"
        :step="Number(duration.step)"
        :range="{ min: Number(duration.minimum), max: Number(duration.maximum) }"
        @update:model-value="(value) => onChangeDuration(value)"
      ></slider>
      <div class="duration-range">
        <p>{{ duration.minimum }} {{ duration.unit }}</p>
        <p>{{ duration.maximum }} {{ duration.unit }}</p>
      </div>
    </div>

    <div class="loan-btn">
      <a :href="link" class="btn goto-registration-page">{{ button }}</a>
    </div>
    <div class="loan-summary">
      <p class="loan-amount-title">
        {{ amount.subtitle }}:&nbsp;
        <span>
          <span id="loan-amount-value">{{ addSeparator(amountValue) }}</span>
          {{ amount.unit }}
        </span>
      </p>
      <p class="loan-period-title">
        {{ duration.subtitle }}:&nbsp;
        <span>
          <span id="loan-amount-period">{{ dataSplaty(durationValue) }}</span>
        </span>
      </p>
    </div>
    <div class="advantages-slider__block">
      <div class="slider-block__container">
        <swiper
          :modules="$swiperModules"
          :slides-per-view="3"
          :space-between="20"
          :grabCursor="true"
          :pagination="{ clickable: true }"
          :breakpoints="breakpoints"
        >
          <swiper-slide v-for="(advantage, index) in advantages" :key="index">
            <div class="slider-block__item">
              <img :src="advantage.icon" class="block-item__img" alt="quick-simple" />
              <div class="slider-block__info">
                <div class="block-info__title">
                  <p>{{ advantage.primaryText }}</p>
                </div>
                <div class="block-info__text">
                  <p>{{ advantage.secondaryText }}</p>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { defineAsyncComponent, defineComponent } from 'vue'
import { usePersistStore } from '@/js/store/persist'

export default defineComponent({
  components: {
    slider: defineAsyncComponent(() => import('@/vue/components/Slider.vue')),
  },

  setup() {
    const breakpoints = ref({
      0: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      400: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      800: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    })

    const amount = ref({
      title: '',
      step: 100,
      minimum: 100,
      maximum: 10000,
      unit: 'zł',
      subtitle: '',
    })

    const duration = ref({
      title: '',
      step: 1,
      minimum: 61,
      maximum: 90,
      unit: 'dni',
      subtitle: '',
    })

    const button = ref('')
    const link = ref('#')
    const advantages = ref([])
    const amountValue = ref(1000)
    const durationValue = ref(90)
    const persist = usePersistStore()

    const onChangeAmount = (value) => {
      persist.onChangeLoanAmount(value)
    }

    const onChangeDuration = (value) => {
      persist.onChangeLoanPeriod(value)
    }

    const dataSplaty = (dni) => {
      const futureDate = new Date(Date.now() + dni * 24 * 60 * 60 * 1000)
      const day = futureDate.getDate()
      const month = futureDate.getMonth() + 1
      const year = futureDate.getFullYear()
      return `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`
    }

    const addSeparator = (nStr) => {
      nStr = String(nStr)
      const parts = nStr.split('.')
      const integerPart = parts[0]

      const rgx = /(\d)(?=(\d{3})+(?!\d))/g
      return integerPart.replace(rgx, '$1 ')
    }

    onMounted(() => {
      persist.onChangeLoanAmount(amountValue.value)
      persist.onChangeLoanPeriod(durationValue.value)

      const element = document.getElementById('loan-slider')

      amount.value = JSON.parse(element.getAttribute('data-amount'))[0]
      amountValue.value = amount.value.default
      onChangeAmount(amountValue.value)

      duration.value = JSON.parse(element.getAttribute('data-duration'))[0]
      durationValue.value = duration.value.default
      onChangeDuration(durationValue.value)

      button.value = element.getAttribute('data-button')
      link.value = element.getAttribute('data-link')

      const encodedData = element.getAttribute('data-advantages')
      const parsedData = new DOMParser().parseFromString(encodedData, 'text/html')
      const decodedString = parsedData.body.textContent
      advantages.value = JSON.parse(decodedString)
    })

    return {
      amountValue,
      durationValue,
      amount,
      duration,
      advantages,
      button,
      link,
      advantages,
      breakpoints,
      onChangeAmount,
      onChangeDuration,
      dataSplaty,
      addSeparator,
    }
  },
})
</script>
